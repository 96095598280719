#rebate_report {
  .rebate_report_calendar {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 16px;

    .calendar_content_main {
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: calc(85% - 16px) calc(15% - 16px);
      column-gap: 32px;
      grid-template-areas: 'left right';

      .calendar_content_main_left {
        .top {
          display: grid;
          grid-template-columns: calc(50% - 15px) calc(50% - 15px);
          column-gap: 30px;

          /deep/ .el-form-item {
            margin-bottom: 30px;
          }

          .el-cascader /deep/ {
            width: 100%;

            .el-cascader__label {
              font-size: 20px;
              font-weight: 200;
              line-height: 1.5;
              color: $primary-purple;
              @include rtl-sass-prop(padding-left, padding-right, 0);
              top: 9px;
            }
          }
        }

        .bottom .calendar {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .calendar_content_main_right {
        position: relative;
        .el-button {
          position: absolute;
          top: 10%;

          &:not(:first-child) {
            margin-left: 0;
            top: 60%;
          }
        }
      }
    }

    // 4个盒子
    .calendar_box {
      width: 100%;
      margin-top: 40px;

      ul {
        width: calc(350px * 2 + 25px);
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 32px;
        row-gap: 25px;
        margin: 0 auto;

        li {
          border: $container-border;
          border-radius: $container-radius;

          .top {
            width: 100%;
            height: 73px;
            padding: 24px 24px 0;

            p {
              font-size: 28px;
              font-family: 'GTWalsheimPro-Bold';
              line-height: 1.56;
              color: $primary-purple;
            }
          }

          .bottom {
            width: 100%;
            padding: 0 24px 24px 24px;
            display: inline-block;

            p {
              font-size: 32px;
              font-family: 'GTWalsheimPro-Bold';
              line-height: 1.38;
              color: $primary-green;
              margin-bottom: 8px;
            }

            span {
              font-size: 16px;
              font-weight: 200;
              line-height: 1.56;
              color: $primary-green;
            }

            .bottom_left {
              width: 40%;
            }

            .bottom_left,
            .bottom_right {
              @include rtl-sass-value(float, left, right);

              p {
                font-size: 32px;
                font-family: 'GTWalsheimPro-Bold';
                line-height: 1.38;
                color: $primary-green;
                margin-bottom: 8px;
              }

              > span {
                font-size: 16px;
                font-weight: 200;
                line-height: 1.56;
                color: $primary-green;
              }
            }
          }
        }
      }
    }
  }

  //表格部分
  .content_box {
    width: 100%;

    .select {
      p {
        font-size: 32px;
        font-family: 'GTWalsheimPro-Bold';
        line-height: 1.38;
        color: $primary-purple;
        margin-bottom: 29px;
      }
    }

    .table_box {
      a {
        color: $primary-purple;
        text-decoration: underline;
      }
      /deep/ .el-table--border {
        th {
          border: 0;
        }
      }

      /deep/ .el-table--border td {
        border: unset;
      }
    }
  }

  // 共用外層跟內層的 css
  /deep/ {
    thead {
      tr {
        .is-sortable {
          @include rtl-sass-prop(padding-left, padding-right, 30px);

          .cell {
            display: inline-block;
            min-height: unset;
          }
        }

        .cell {
          min-height: 40px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #rebate_report {
    .rebate_report_calendar {
      .calendar_content_main {
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        grid-template-areas:
          'left'
          'right';
        text-align: center;

        .calendar_content_main_right {
          .el-button {
            top: 20% !important;
            position: relative;
          }
        }
      }

      .calendar_box {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  #rebate_report .rebate_report_calendar {
    .calendar_content_main .calendar_content_main_left {
      margin-bottom: 20px;
    }
    .calendar_box ul {
      width: 100%;
      column-gap: 24px;
    }
  }
}

@media (max-width: 550px) {
  #rebate_report {
    .rebate_report_calendar {
      .calendar_content_main {
        .calendar_content_main_left {
          margin-bottom: 30px;

          .top {
            grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

            /deep/ .el-form-item {
              margin-bottom: 12px;
            }
            .el-cascader /deep/ {
              margin-bottom: 12px;

              .el-cascader__label {
                font-size: 16px;
              }
            }
          }
        }
      }

      .calendar_box ul {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

        li {
          width: 100%;
        }
      }
    }

    .content_box .select p {
      font-size: 24px;
      text-align: center;
    }
  }
}
