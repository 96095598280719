/deep/ .el-dialog {
  margin-top: 0 !important;

  .el-dialog__body {
    padding: 26px 48px 56px;

    .title {
      font-size: 20px;
      font-weight: 300;
      line-height: 1.5;
      color: $primary-purple;
    }

    .main {
      display: grid;
      grid-template-columns: 40% 60%;
      min-height: 400px;

      .info {
        margin-top: 72px;

        ul li {
          font-size: 18px;
          line-height: 1.44;

          span {
            font-weight: bold;
            color: $primary-purple;
          }
          p {
            font-weight: 300;
            color: $primary-purple;
            margin-top: 8px;
          }

          &:first-child {
            p {
              margin-bottom: 40px;
            }
          }
        }
      }

      .echarts {
        width: 100%;
        height: 100%;

        #donut {
          width: 100%;
          height: 100%;
        }
      }
    }

    .table_content {
      // table表格
      .table_box {
        width: 100%;
        height: 391px;
        margin-bottom: 0;
      }
      .el-collapse-item__header {
        margin-bottom: 40px;
      }

      /deep/ thead tr .cell {
        min-height: 87px !important;
      }
    }
  }
}

@media (max-width: 767px) {
  /deep/ .el-dialog .el-dialog__body {
    padding: 30px 24px 48px;

    .main {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      min-height: unset;

      .info {
        margin-top: 32px;
      }

      .echarts {
        min-height: 400px;
        #donut {
          min-height: 400px;
        }
      }
    }

    .table_content {
      .el-collapse-item__header {
        background-color: $primary-purple;
        padding: 0 24px 0;
        display: flex;
        align-items: center;
        height: 78px;
        position: relative;
        margin-bottom: 0;

        span {
          font-size: 20px;
          font-weight: bold;
          line-height: 1.5;
          color: #ffffff;
        }

        &:after {
          position: absolute;
          bottom: 0;
          content: '';
          width: calc(100% - 48px);
          height: 1px;
          border: solid 1px #6697ff;
        }

        .el-collapse-item__arrow,
        .el-icon-arrow-right {
          color: #ffffff;
        }
      }
    }
  }
}
